import * as React from "react";
import { Title, Meta } from "react-head";

interface Props {
  title?: string;
  description?: string;
  imageUrl?: string;
  videoUrl?: string;
}

const MetaTags: React.FC<Props> = ({ title, description, imageUrl, videoUrl }) => {
  const formattedTitle = title?.includes("SocialCoach") ? title : [title, "SocialCoach"].filter(Boolean).join(" - ");

  return (
    <>
      <Title>{formattedTitle}</Title>
      <Meta name="title" content={formattedTitle} />
      <Meta property="og:title" content={formattedTitle} />
      <Meta property="og:type" content="website" />
      {description && (
        <>
          <Meta name="description" content={description} />
          <Meta property="og:description" content={description} />
        </>
      )}
      {imageUrl && <Meta property="og:image" content={imageUrl} />}
      {videoUrl && <Meta property="og:video" content={videoUrl} />}
    </>
  );
};

export default MetaTags;
