import * as React from "react";
import { Grid, Header, Image } from "semantic-ui-react";

import { fullNameFrom, printOnlyDate } from "../../../../../utils";
import { Svg } from "../../../../../assets";
import { PlayerAccount, PricePlanTier, VideoCatalystStatus } from "../../../../../interfaces";

import { BasicContainer } from "../../../../../styling/baseStyle";
import { ButtonLink, EditableField } from "../../../../../components";
import { useIntl } from "react-intl";
import { PlayerDetailType, descriptors } from "./descriptors";

import "../../styles.scss";
import { ActionList } from "../../../../Admin/MergedActions";
import { VideoProjectServices } from "../../../../../services";
import * as IT from "../../../../../interfaces";
import { VideoCreditsModal } from "../VideoCreditsModal";
interface OwnProps {
  playerAccount: PlayerAccount;
  verificationDate?: string;
  subscriptionType?: PricePlanTier;

  showResetPassword: boolean;
  isActivePlayer: boolean;
  scPlusFeatureActive?: boolean;
  handleChangeEmailValue: (value: string) => void;
  resetPasswordHandler: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  handleCancelPlayer: () => void;
  handleChangeSubscriptionType?: (type: PricePlanTier) => void;
}

type Props = OwnProps;

interface MenuElement {
  content: string;
  icon: string;
}
const PlayerInfoFC: React.FC<Props> = ({
  showResetPassword,
  playerAccount,
  verificationDate,
  isActivePlayer,
  subscriptionType,
  scPlusFeatureActive,
  resetPasswordHandler,
  handleChangeEmailValue,
  handleCancelPlayer,
  handleChangeSubscriptionType,
}) => {
  const { formatMessage } = useIntl();

  const [playerEmailUpdated, setPlayerEmailUpdated] = React.useState<string>(playerAccount.email || "");
  const [options, setOptions] = React.useState<MenuElement[]>([]);
  const [showCreditsModal, setShowCreditsModal] = React.useState(false);
  const [vcStatus, setVCStatus] = React.useState<VideoCatalystStatus>();

  const withFullName = Boolean(playerAccount.firstName || playerAccount.lastName);
  const emailLink = <a href={`mailto:${playerAccount.email}`}>{playerAccount.email}</a>;

  React.useEffect(() => {
    if (playerEmailUpdated !== playerAccount.email && playerEmailUpdated !== "") {
      handleChangeEmailValue(playerEmailUpdated);
    }
  }, [playerEmailUpdated]);

  const getVCStatus = async () => {
    if (playerAccount.playerId) {
      const status = await VideoProjectServices.getVideoCatalystStatus(playerAccount.playerId);
      setVCStatus(status);
    }
  };

  React.useEffect(() => {
    getVCStatus();
  }, [playerAccount.playerId]);

  const ACTION_UPGRADE = "Upgrade to SocialCoach+";
  const ACTION_CHANGE_CREDITS = "Update Video Credits";
  const ACTION_DOWNGRADE = "Downgrade to SocialCoach Basic";
  const ACTION_DEACTIVATE = "Deactivate Player";

  React.useEffect(() => {
    const menuElements = [];
    const isBasic = subscriptionType === PricePlanTier.PLAYER_BASIC;

    if (scPlusFeatureActive) {
      if (isBasic) {
        menuElements.push({
          content: ACTION_UPGRADE,
          icon: "level up",
        });
      } else {
        menuElements.push({
          content: ACTION_CHANGE_CREDITS,
          icon: "file video outline icon",
        });
        menuElements.push({
          content: ACTION_DOWNGRADE,
          icon: "level down",
        });
      }
    }

    if (isActivePlayer) {
      menuElements.push({
        content: ACTION_DEACTIVATE,
        icon: "user cancel",
      });
    }

    setOptions(menuElements);
  }, [isActivePlayer, scPlusFeatureActive, subscriptionType]);

  const handleThreeDotsMenuAction = (index: number): void => {
    const option = options[index].content;
    switch (option) {
      case ACTION_DEACTIVATE:
        handleCancelPlayer();
        break;
      case ACTION_UPGRADE:
        if (handleChangeSubscriptionType) handleChangeSubscriptionType(PricePlanTier.PLAYER_PLUS);
        break;
      case ACTION_DOWNGRADE:
        if (handleChangeSubscriptionType) handleChangeSubscriptionType(PricePlanTier.PLAYER_BASIC);
        break;
      case ACTION_CHANGE_CREDITS:
        setShowCreditsModal(true);
        break;
      default:
        break;
    }
  };

  const getSubscriptionTypeWithPlayerStatus = (
    isActive: boolean,
    featureFlag?: boolean,
    subscriptionType?: IT.PricePlanTier
  ) => {
    if (!isActive) {
      return "Inactive";
    } else if (featureFlag === false || featureFlag === undefined) {
      return "Active";
    }

    if (subscriptionType === undefined) {
      return "";
    }

    switch (subscriptionType) {
      case IT.PricePlanTier.PLAYER_BASIC:
        return "SocialCoach Basic";
      case IT.PricePlanTier.PLAYER_PLUS:
        const creditAmount = (vcStatus?.creditsUsed || 0) + (vcStatus?.creditsRemaining || 0);
        const suffix = vcStatus ? ` (${vcStatus.unlimitedCredits ? "Unlimited" : creditAmount} Credits)` : "";
        return `SocialCoach+${suffix}`;
    }
  };

  return (
    <BasicContainer>
      <Grid.Row className={"playerInfoSection"}>
        <Image circular wrapped size="tiny" src={playerAccount.photoUrl || Svg.noImageuser} />

        <Header as="h2" textAlign="left">
          {withFullName && playerAccount ? fullNameFrom(playerAccount!) : emailLink}
        </Header>
      </Grid.Row>
      {withFullName && (
        <Grid.Row className={"playerEmailSection "}>
          <Grid container={true}>
            <EditableField
              width={10}
              headerType={"h3"}
              dataElmId={`playerEmail${playerAccount.playerId}`}
              currentValue={playerEmailUpdated}
              maxLength={200}
              handleChangeValue={setPlayerEmailUpdated}
              cancelable={true}
              disable={false}
            />
          </Grid>
        </Grid.Row>
      )}

      {showResetPassword && (
        <Grid.Row className="resetPlayerPassword">
          <ButtonLink
            message={descriptors[PlayerDetailType.resetPassword]}
            onClick={resetPasswordHandler}
            type={"underline"}
          />
        </Grid.Row>
      )}
      {verificationDate && (
        <Grid.Row className={"playerInfoSection"}>
          <p className={"marginTop"}>
            <Grid.Column className={"subscriptionTypeSection noPaddingLeft"} floated="left">
              <Header as="h3" className={"permissionTitle"}>
                {formatMessage({ ...descriptors[PlayerDetailType.subscriptionTitle] })}
              </Header>
              <div>
                <Header as="h3" className={"subscriptionLabel"}>
                  {getSubscriptionTypeWithPlayerStatus(isActivePlayer, scPlusFeatureActive, subscriptionType)}
                </Header>
                {isActivePlayer && (
                  <span className={"subscriptionMenu"}>
                    <ActionList
                      items={options}
                      handleAction={async index => handleThreeDotsMenuAction(index)}
                      iconName={"ellipsis vertical"}
                    />
                  </span>
                )}
              </div>
              <div className={"subscriptionDate"}>{"Subscribed " + printOnlyDate(verificationDate)}</div>
            </Grid.Column>
          </p>
        </Grid.Row>
      )}
      <VideoCreditsModal
        playerId={playerAccount.playerId}
        vcStatus={vcStatus}
        open={showCreditsModal}
        onUpdateStatus={s => setVCStatus(s)}
        onClose={() => setShowCreditsModal(false)}
      />
    </BasicContainer>
  );
};

export const PlayerInfo = PlayerInfoFC;
