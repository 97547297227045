import { client as axios } from "./axiosClient";
import { Constants } from "@socialcoach/services";
import { catchServiceException } from "@socialcoach/services";
import * as DTO from "../interfaces/dtos";

const VIDEO_PROJECT_SERVICE = `${Constants.API_URL}/video-catalyst`;

export class VideoProjectServices {
  @catchServiceException()
  static async create(videoProject: DTO.VideoProjectRequest): Promise<DTO.VideoProject> {
    const { data } = await axios.post<DTO.VideoProject>(
      `${VIDEO_PROJECT_SERVICE}`,
      {
        ...videoProject,
      },
      {
        withCredentials: true,
      }
    );

    return data;
  }

  @catchServiceException()
  static async update(videoProject: DTO.VideoProjectUpdateRequest, id: number): Promise<DTO.VideoProject> {
    const response = await axios.put<DTO.VideoProject>(
      `${VIDEO_PROJECT_SERVICE}/${id}`,
      {
        ...videoProject,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async get(id: string | number): Promise<DTO.VideoProjectDetail> {
    const { data } = await axios.get<DTO.VideoProjectDetail>(`${VIDEO_PROJECT_SERVICE}/${id}`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async getPlayersWithVideoProjects(coachId: string): Promise<DTO.PlayerResumeResponse[]> {
    const { data } = await axios.get<DTO.PlayerResumeResponse[]>(`${VIDEO_PROJECT_SERVICE}/coach/${coachId}/players`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async getPlayerVideoProjects(playerId: number): Promise<DTO.Page<DTO.VideoProject>> {
    const body = {
      playerId,
    };
    const { data } = await axios.post<DTO.Page<DTO.VideoProject>>(
      `${VIDEO_PROJECT_SERVICE}/player/search?page=0&size=400&sort=createdDate,DESC`,
      { ...body },
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async getLatestVideoProjectPreferences(playerId: number): Promise<DTO.VideoPreferences> {
    const { data } = await axios.get<DTO.VideoPreferences>(
      `${VIDEO_PROJECT_SERVICE}/player/preferences?playerId=${playerId}`,
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async getVideoCatalystStatus(playerId: number): Promise<DTO.VideoCatalystStatus> {
    const { data } = await axios.get<DTO.VideoCatalystStatus>(`${VIDEO_PROJECT_SERVICE}/player/${playerId}/status`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async getPlayerSummaries(coachId: string): Promise<DTO.VideoProjectPlayerSummary[]> {
    const { data } = await axios.get<DTO.VideoProjectPlayerSummary[]>(
      `${VIDEO_PROJECT_SERVICE}/coach/${coachId}/players/summary`,
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async search(
    coachId: string,
    sort: string,
    filters?: DTO.SearchFilter | null,
    page: number | string = 0,
    size: number = 15
  ): Promise<DTO.Page<DTO.PlayerVideoProjectResponse>> {
    const customFilters = {
      coachId,
      status: filters?.status ? filters?.status : undefined,
    };

    const response = await axios.post<DTO.Page<DTO.PlayerVideoProjectResponse>>(
      `${VIDEO_PROJECT_SERVICE}/search?page=${page}&size=${size}&sort=${sort}`,
      {
        ...customFilters,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async getAll(
    coachId: string | number,
    playerId: string | number,
    sort: string = "createdDate,DESC",
    page: number | string = 0,
    size: number = 2000
  ): Promise<DTO.Page<DTO.PlayerVideoProjectResponse>> {
    const customFilters = {
      playerId,
      coachId,
    };
    const response = await axios.post<DTO.Page<DTO.PlayerVideoProjectResponse>>(
      `${VIDEO_PROJECT_SERVICE}/search?page=${page}&size=${size}&sort=${sort}`,
      {
        ...customFilters,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async getAsEditor(
    sort: string,
    filters?: DTO.SearchVideoProjectFilter | null,
    page: number | string = 0,
    size: number = 15
  ): Promise<DTO.Page<DTO.PlayerVideoProjectResponse>> {
    const response = await axios.post<DTO.Page<DTO.PlayerVideoProjectResponse>>(
      `${VIDEO_PROJECT_SERVICE}/editor/search?page=${page}&size=${size}&sort=${sort}`,
      {
        ...filters,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async videoProjectByPlayer(
    coachId?: string,
    playerId?: number | string,
    page: number | string = 0,
    size: number = 5,
    sort: string = "createdDate,DESC"
  ): Promise<DTO.Page<DTO.PlayerVideoProjectResponse>> {
    const customFilters = {
      playerId,
      coachId,
    };
    const response = await axios.post<DTO.Page<DTO.PlayerVideoProjectResponse>>(
      `${VIDEO_PROJECT_SERVICE}/search?page=${page}&size=${size}&sort=${sort}`,
      {
        ...customFilters,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async getCoachConfig(coachId: string): Promise<DTO.VideoProjectCoachConfig> {
    const { data } = await axios.get<DTO.VideoProjectCoachConfig>(`${VIDEO_PROJECT_SERVICE}/coach/${coachId}/config`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async updatePlayerConfig(
    playerId: number,
    config: DTO.VideoProjectPlayerConfigRequest
  ): Promise<DTO.VideoCatalystStatus> {
    const { data } = await axios.post<DTO.VideoCatalystStatus>(
      `${VIDEO_PROJECT_SERVICE}/player/${playerId}/config`,
      config,
      { withCredentials: true }
    );
    return data;
  }

  @catchServiceException()
  static async updateCoachConfig(
    coachId: string,
    config: DTO.VideoProjectCoachConfig
  ): Promise<DTO.VideoProjectCoachConfig> {
    const { data } = await axios.post<DTO.VideoProjectCoachConfig>(
      `${VIDEO_PROJECT_SERVICE}/coach/${coachId}/config`,
      config,
      { withCredentials: true }
    );
    return data;
  }

  @catchServiceException()
  static async getUsageReport(
    coachId: string,
    request: DTO.VideoProjectUsageReportRequest
  ): Promise<DTO.VideoProjectUsageReport> {
    const { data } = await axios.post<DTO.VideoProjectUsageReport>(
      `${VIDEO_PROJECT_SERVICE}/coach/${coachId}/usage`,
      request,
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async archive(id: number): Promise<void> {
    const { data } = await axios.put<void>(`${VIDEO_PROJECT_SERVICE}/archive/${id}`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async sendMessage(id: number, message: string): Promise<void> {
    const { data } = await axios.post<void>(
      `${VIDEO_PROJECT_SERVICE}/${id}/message`,
      {
        content: message,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async requestRevision(id: number, message: string): Promise<void> {
    const request = {
      content: message,
    };
    const { data } = await axios.post<void>(
      `${VIDEO_PROJECT_SERVICE}/${id}/revision`,
      {
        message: request,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async acknowledgeMessage(id: number, type: string = "MESSAGES"): Promise<void> {
    const { data } = await axios.put<void>(`${VIDEO_PROJECT_SERVICE}/${id}/acknowledge/${type}`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async addClientNotes(playerId: number, notes: string): Promise<void> {
    const { data } = await axios.post<void>(
      `${VIDEO_PROJECT_SERVICE}/player/client-notes`,
      {
        notes,
        playerId,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }
}
