import { Grid, Modal, Progress } from "semantic-ui-react";
import * as React from "react";
import { UploadProgressMap } from "../../interfaces";
import "./styles.scss";

interface UploadProgressProps {
  open: boolean;
  header?: string;
  progressMap?: UploadProgressMap;
}

type Props = UploadProgressProps;

const UploadProgressModalFC: React.FC<Props> = ({ header = "Uploading Project Files", open, progressMap }) => {
  return (
    <Modal size={"mini"} open={open}>
      <Modal.Header>{header}</Modal.Header>

      <Modal.Content>
        <Grid>
          {progressMap &&
            Object.entries(progressMap).map(([key, { progress }]) => {
              return (
                <Grid.Row key={`progress-row-${key}`}>
                  <Progress
                    percent={Math.trunc(progress * 100)}
                    className={"uploadProgressBar"}
                    progress
                    autoSuccess
                    indicating
                  >
                    {`File ${key}`}
                  </Progress>
                </Grid.Row>
              );
            })}
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

export const UploadProgressModal = UploadProgressModalFC;
