const player = "/player";

export const mediaFirebaseUrl = (coachId: string): string => `coaches/${coachId}/prompts/media`;
export const productMediaFirebaseUrl = (product: "videoProject" | "teleprompter", playerId: string): string =>
  `${product}/players/${playerId}/media`;
export const mediaBrandFirebaseUrl = (brandId: string): string => `brands/media/${brandId}`;
export const mediaEditedBrandImageFirebaseUrl = (userId: number): string => `brands/media/editedByUser/${userId}/`;

export const mediaEditedFirebaseUrl = (coachId: string): string => `coaches/${coachId}/prompts/mediaEdited`;

export const URLS = {
  dashboard: "",
  socialHome: "https://socialcoach.io",
  home: "/",
  login: "/sign-in",
  register: "/sign-up",
  forgotPassword: "/forgot-password",
  changePassword: "/reset-password/:userId/:actionToken",
  terms: "/terms",
  privacyPolicy: "/privacy-policy",
  activatePlayerUser: "/activate/player/:userId/:actionToken",
  activateUser: "/activate/:userId/:actionToken",
  resendEmail: "/resend-verify-account",
  landingPage: "/:customLink",
  contentPage: "/v/:pageSlug",
  notFound: "/notfound",
  brandPost: "/publish",
  videoScripts: "/video-scripts",
  videoScript: "/video-script/:id",
  newVideoScript: "/video-script",
  videoProject: "/videoproject/detail/:id",

  coach: {
    dashboard: "/dashboard",
    digestEmails: "/digest-emails",
    appSettings: "/settings",
    profile: "/sign-up-profile",
    pricePlan: "/price-plan",
    marketingSite: "/coaching-page",
    landingPreview: "/preview-landing/:userId",
    prompt: "/prompt/:promptId",
    newPrompt: "/new-prompt/",
    library: "/library",
    players: "/coach-players",
    editPlayer: "/coach-players/:playerId",
    compliance: "/compliance",
    campaigns: "/campaigns",
    newCampaigns: "/campaigns/new/",
    editCampaigns: "/campaigns/:campaignId",
    questionnaire: "/questionnaire",
    subCoaches: "/sub-coaches",
    editSubCoach: "/sub-coaches/:userId",
    newSubCoach: "/sub-coaches/new",
    newPlayer: "/coach-players/new",
    alerts: "/alerts",
    post: "/post-prompt/:promptId",
    videoCatalyst: "/videocatalyst",
    newVideoProject: "/videoproject/new",
  },
  player: {
    library: player + "/library/:tab?",
    libraryPrompts: player + "/library/prompts",
    libraryScripts: player + "/library/scripts",
    libraryVC: player + "/library/video-catalyst",
    dashboard: player + "/dashboard",
    settings: player + "/settings/:tab?",
    settingsCustomizations: player + "/settings/customizations",
    settingsSocialNetworks: player + "/settings/social-networks",
    embeddedSocialAccounts: player + "/social-embedded",
    paymentInfo: player + "/payment-details",
    firstLogin: player + "/first-login",
    firstPicture: player + "/first-picture",
    firstWebAddress: player + "/first-web-address",
    firstQuestions: player + "/first-questions",
    firstSocialNetworks: player + "/first-social-networks",
    firstCongrats: player + "/congrats",
    login: player + "/sign-in/:coachId",
    signUp: player + "/sign-up/:coachId",
    signUpBasic: player + "/sign-up/",
    success: player + "/success",
    post: player + "/post",
    compliance: player + "/compliance",
    newVideoProject: player + "/videoproject/new",
    videoScript: player + "/video-script/:id",
    newVideoScript: player + "/video-script",
    recordVideo: player + "/record-video",
  },
  admin: {
    dashboard: "/admin/dashboard",
    prospects: "/admin/prospects",
    players: "/admin/players",
    login: "/admin/sign-in",
    contentPageEditor: "/admin/mvp/:slug",
    newContentPage: "/admin/mvp",
  },
  editor: {
    editQueueList: "/editor/queueList",
    editVideo: "/editor/videoproject/:id",
  },
};
