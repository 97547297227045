import * as React from "react";
import {
  VideoCatalystStatus,
  VideoProjectCoachConfig,
  VideoProjectPlayerConfigRequest,
} from "../../../../../interfaces";
import { Button, Form, FormField, Input, Modal } from "semantic-ui-react";
import { useEffect, useMemo } from "react";
import { VideoProjectServices } from "../../../../../services";
import { AppContext } from "../../../../../providers";
import { get } from "lodash";

interface OwnProps {
  playerId: number | undefined;
  vcStatus: VideoCatalystStatus | undefined;
  open: boolean;
  onUpdateStatus: (status: VideoCatalystStatus) => void;
  onClose: () => void;
}

type Props = OwnProps;

const VideoCreditsModalFC: React.FC<Props> = ({ playerId, vcStatus, open, onUpdateStatus, onClose }) => {
  const { userContext } = React.useContext(AppContext);
  const coachId: string = get(userContext, "coach.id", "");

  const [configLoading, setConfigLoading] = React.useState(false);
  const [editableCredits, setEditableCredits] = React.useState<number>();
  const [coachVCConfig, setCoachVCConfig] = React.useState<VideoProjectCoachConfig>();

  const getCoachVCConfig = async () => {
    if (coachId) {
      const config = await VideoProjectServices.getCoachConfig(coachId);
      setCoachVCConfig(config);
    }
  };

  useEffect(() => {
    getCoachVCConfig();
  }, [coachId]);

  useEffect(() => {
    const credits = (vcStatus?.creditsUsed || 0) + (vcStatus?.creditsRemaining || 0);
    setEditableCredits(credits);
  }, [vcStatus]);

  const showSaveCredits = useMemo(() => {
    return !vcStatus?.unlimitedCredits;
  }, [vcStatus?.unlimitedCredits]);

  const canSaveCredits = useMemo(() => {
    return (
      !vcStatus?.unlimitedCredits && editableCredits && coachVCConfig?.defaultPlayerVideoCredits !== editableCredits
    );
  }, [coachVCConfig?.defaultPlayerVideoCredits, editableCredits, vcStatus?.unlimitedCredits]);

  const canResetCredits = useMemo(() => {
    const currentCredits = (vcStatus?.creditsUsed || 0) + (vcStatus?.creditsRemaining || 0);
    return vcStatus?.unlimitedCredits || coachVCConfig?.defaultPlayerVideoCredits !== currentCredits;
  }, [
    vcStatus?.unlimitedCredits,
    coachVCConfig?.defaultPlayerVideoCredits,
    vcStatus?.creditsUsed,
    vcStatus?.creditsRemaining,
  ]);

  const saveVideoCreditChanges = async (reset: boolean = false) => {
    setConfigLoading(true);
    const request: VideoProjectPlayerConfigRequest = reset
      ? {
          resetToDefault: true,
        }
      : {
          monthlyVideoCredits: editableCredits,
        };
    const status = await VideoProjectServices.updatePlayerConfig(playerId!!, request!!);
    setConfigLoading(false);
    onUpdateStatus(status);
    onClose();
  };

  return (
    <Modal closeIcon open={open} size={"tiny"} onClose={onClose}>
      <Modal.Header>Edit Video Credits</Modal.Header>

      <Modal.Content>
        <Form>
          <FormField>
            <label>Monthly Credits</label>
            {vcStatus?.unlimitedCredits && <div>Unlimited Credits</div>}
            {!vcStatus?.unlimitedCredits && (
              <Input
                placeholder="Monthly Credits"
                type={"number"}
                value={editableCredits}
                onChange={data => {
                  setEditableCredits(data.target.valueAsNumber);
                }}
              />
            )}
          </FormField>
        </Form>
      </Modal.Content>

      <Modal.Actions>
        {canResetCredits && (
          <Button
            secondary
            data-elm-id={`basicModalYesBtn`}
            loading={configLoading}
            onClick={() => {
              saveVideoCreditChanges(true);
            }}
          >
            Reset to default ({coachVCConfig?.defaultPlayerVideoCredits} credits)
          </Button>
        )}
        {showSaveCredits && (
          <Button
            primary
            data-elm-id={`basicModalYesBtn`}
            loading={configLoading}
            disabled={!canSaveCredits}
            onClick={() => {
              saveVideoCreditChanges();
            }}
          >
            Save
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export const VideoCreditsModal = VideoCreditsModalFC;
