import Check from "./bluecheck.png";
import Error from "./error.png";
import IG from "./instagram.png";
import IG_STORY from "./instagramStory.png";
import FB from "./facebook.png";
import TW from "./twitter.png";
import LI from "./linkedin.png";
import TT from "./tik-tok.png";
import IGLogo from "./instagramLogo.png";
import FBLogo from "./facebookLogo.png";
import TWLogo from "./twitterLogo.png";
import TTLogo from "./icTikTokSquare.png";
import LILogo from "./linkedInLogo.png";
import LIMedLogo from "./linkedinMed96.png";
import PreviewPhone from "./phoneFrame.png";
import Logo from "./logo.png";
import LogoWhite from "./logo_white.png";
import LogoCircleDark from "./logo_circle_dark.png";
import Profile from "./profile.png";

import ContentExample1 from "./contentExample1.png";
import ContentExample2 from "./contentExample2.png";
import ContentExample3 from "./contentExample3.png";
import ContentExample4 from "./contentExample4.png";
import ContentExample5 from "./contentExample5.png";
import ContentExample6 from "./contentExample6.png";

import BrandPostSuccess from "./brandPostSuccess.png";

export const Png = {
  IG,
  FB,
  TW,
  LI,
  TT,
  IG_STORY,
  IGLogo,
  FBLogo,
  TWLogo,
  LILogo,
  TTLogo,
  LIMedLogo,
  PreviewPhone,
  Logo,
  Error,
  Check,
  Profile,
  LogoWhite,
  LogoCircleDark,
  ContentExample1,
  ContentExample2,
  ContentExample3,
  ContentExample4,
  ContentExample5,
  ContentExample6,
  BrandPostSuccess,
};
