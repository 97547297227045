import * as React from "react";
import { useState } from "react";
import * as DTO from "../../../interfaces/dtos";
import { RouteComponentProps } from "react-router-dom";
import { Grid, Loader } from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";

import { AdminSideBar, TitleSection } from "../../../components";

import { WhiteContainer } from "../../../styling/baseStyle";
import { ContentPageServices } from "../../../services/ContentPageServices";
import { ContentPageForm } from "./components/ContentPageForm";

import "./styles.scss";
import { getFirebaseStorage, URLS } from "../../../utils";
import { ConfigContext } from "../../../contexts/appContexts";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { UploadProgressModal } from "../../../components/UploadProgressModal";
import { UploadProgressMap } from "../../../interfaces";
import { ContentPageDisplay } from "./components/ContentPageDisplay";

type Props = RouteComponentProps;

const ContentPageEditorFC: React.FC<Props> = ({ history, match: { params } }) => {
  const [pageId, setPageId] = useState<number>();
  const [pageInfo, setPageInfo] = useState<DTO.ContentPageInfo>();
  const [pageRequest, setPageRequest] = useState<DTO.ContentPageRequest>();
  const [fileToUpload, setFileToUpload] = React.useState<File | undefined>();
  const [loading, setLoading] = useState(false);
  const [savingStatus, setSavingStatus] = useState<string | undefined>();
  const [uploadProgressMap, setUploadProgressMap] = React.useState<UploadProgressMap>();
  const [uploading, setUploading] = React.useState<boolean>(false);
  const { firebase } = React.useContext(ConfigContext);

  React.useEffect(() => {
    const getPage = async (pageSlug: string) => {
      setLoading(true);
      const page = await ContentPageServices.getPageBySlug(pageSlug);
      setLoading(false);
      setPageId(page.contentPageId);
      setPageInfo(page);
      setPageRequest({
        title: page.title,
        caption: "",
        playerId: page.playerId,
        coachId: page.coachId,
        media: page.media,
      });
    };

    if (params["slug"]) {
      getPage(params["slug"]).catch(console.error);
    } else {
      setPageRequest({
        caption: "",
        coachId: undefined,
        media: [],
        playerId: undefined,
        title: "",
      });
    }
  }, []);

  const savePage = async () => {
    if (pageRequest) {
      setLoading(true);

      const media = (pageRequest.media || [])[0];
      if (fileToUpload && media) {
        media.uri = await saveFile(fileToUpload);
      }

      if (pageId) {
        await ContentPageServices.update(pageId, pageRequest);
      } else {
        const newPage = await ContentPageServices.save(pageRequest);
        history.replace(URLS.admin.contentPageEditor.replace(":slug", `${newPage.slug}`));
      }
      setLoading(false);
    }
  };

  const saveFile = async (file: File): Promise<string> => {
    setUploading(true);
    const extension = file.type.split("/").pop();
    const filename = `${uuidv4()}.${extension}}`;
    const destUri = `${firebase.baseImagesFolder}/contentPages/admin/${filename}`;
    const storage = getFirebaseStorage(firebase);
    const storageRef = ref(storage, destUri);
    const uploadTask = uploadBytesResumable(storageRef, file);

    // set Firebase callback functions
    uploadTask.on("state_changed", snapshot => {
      const uploadProgress = snapshot.bytesTransferred / snapshot.totalBytes;
      setUploadProgressMap({ 1: { progress: uploadProgress, task: undefined } });
    });

    await uploadTask;
    const uri = await getDownloadURL(storageRef);
    setFileToUpload(undefined);
    setUploading(false);
    return uri;
  };

  return (
    <WhiteContainer>
      <AdminSideBar history={history} />
      <Loader active={loading} size="large" />
      <div className={"hugePadding contentPageEditor"}>
        <TitleSection
          title={"Content Page"}
          subtitle={savingStatus}
          ready={true}
          showNextOption={true}
          handleNextAction={savePage}
          customNextLabel={"Save"}
          showLinkOption={false}
          titleSize={6}
          buttonsSize={9}
          nextButtonSize={5}
        />
        <Grid>
          <Grid.Column>
            <Grid.Row className={"contentPageInfoContainer"}>
              <Grid.Column>{pageInfo !== undefined && <ContentPageDisplay pageInfo={pageInfo} />}</Grid.Column>
            </Grid.Row>
            <Grid.Row className={"contentPageFormContainer"}>
              <Grid.Column>
                {pageRequest !== undefined && (
                  <ContentPageForm
                    pageRequest={pageRequest}
                    isNew={!pageId}
                    onUpdate={setPageRequest}
                    onUpdateFile={setFileToUpload}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </div>
      <UploadProgressModal header={"Uploading Page Content"} open={uploading} progressMap={uploadProgressMap} />
    </WhiteContainer>
  );
};

export const ContentPageEditor = ContentPageEditorFC;
