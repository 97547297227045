import * as React from "react";

import "./styles.scss";
import { ContentPageMedia } from "../../../../../interfaces";
import { getValidFiles } from "../../../../../utils";
import { MediaItem } from "../MediaItem";
import { useEffect } from "react";

interface MediaUploaderProps {
  media: ContentPageMedia | undefined;
  onUpdateMedia: (newMedia: ContentPageMedia | undefined, file: File | undefined) => void;
}

/*
 *
 * This Multi-Image uploader is designed to allow users to upload variable image and video media
 * types and reorder them using the DraggableMediaContainer.
 *
 */
const MediaUploaderFC: React.FC<MediaUploaderProps> = ({ media, onUpdateMedia }) => {
  const hiddenFileInput = React.useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    console.log("media", media);
  }, [media]);

  /**
   * handles browse button clicks
   */
  const handleBrowseButtonClicked = (): void => {
    hiddenFileInput.current?.click();
  };

  /**
   * Occurs directly after the user selects files to upload from their local device.
   */
  const handleFilesSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const inputFiles = e.target.files || new FileList();
    let validFiles = getValidFiles(Array.from(inputFiles));

    // no files provided
    if (validFiles.length === 0) {
      return;
    }
    const file = validFiles[0];
    const mediaCategory = file.type.includes("image/") ? "IMAGE" : "VIDEO";

    const newMedia = {
      sortOrder: 0,
      category: mediaCategory,
      uri: URL.createObjectURL(file),
      type: file.type,
      extension: file.type.split("/").pop(),
    } as ContentPageMedia;

    onUpdateMedia(newMedia, file);
  };

  /**
   *  Updates media state when user deletes a media object.
   */
  const handleDeletedMedia = (): void => {
    onUpdateMedia(undefined, undefined);
    hiddenFileInput.current!.value = "";
  };

  return (
    <div className="media-uploader-wrapper">
      <form className="media-uploader-input-form">
        <input
          className="custom-file-input"
          type="file"
          accept={"image/*, video/*"}
          onChange={handleFilesSelected}
          ref={hiddenFileInput}
        />
        {media && (
          <div className={"uploaded-files-manager"}>
            <MediaItem src={media} id={0} handleRemove={handleDeletedMedia} />
          </div>
        )}
        <div className="browse-button" onClick={handleBrowseButtonClicked}>
          {media ? "Replace File" : "Add File"}
        </div>
      </form>
    </div>
  );
};

export const MediaUploader = MediaUploaderFC;
