import * as React from "react";
import { Icon } from "semantic-ui-react";

import "./styles.scss";
import { ContentPageMedia } from "../../../../../interfaces";
import { MediaPlayer } from "../MediaPlayer";

interface OwnProps {
  id: number;
  src: ContentPageMedia;

  handleRemove: (id: number) => void;
}

const MediaItemFC: React.FC<OwnProps> = ({ id, src, handleRemove }: OwnProps) => {
  return (
    <div className={`media-item-wrapper`}>
      <div className={"close-icon-wrapper close-icon-basic"} onClick={e => handleRemove(id)}>
        <Icon name="trash alternate outline" className="close-icon" />
      </div>
      <MediaPlayer media={src} />
    </div>
  );
};

export const MediaItem = MediaItemFC;
