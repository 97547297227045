import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useIntl } from "react-intl";
import {
  Button,
  Dropdown,
  DropdownItemProps,
  Modal,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import { get } from "lodash";

import { SideBar, TitleSection, VideoCatalystListContent } from "../../../components";
import { AppContext } from "../../../providers";
import { coachWithWritePermission, URLS } from "../../../utils";
import { WebComponents } from "../../../interfaces";
import { WhiteContainer, ContentGrid, SocialGrid } from "../../../styling/baseStyle";

import { CatalystType, descriptors } from "./descriptors";
import { RowContainer } from "./styled";
import "./styles.scss";
import { EmptyView } from "../../../components/EmptyView";
import { VideoCreditUsageModal } from "./VideoCreditUsageModal";

type Props = RouteComponentProps;

const VideoCatalystFC: React.FC<Props> = ({ history }) => {
  const { userContext } = React.useContext(AppContext);
  const writePermission = coachWithWritePermission(WebComponents.VIDEO_CATALYST, userContext);
  const { formatMessage } = useIntl();
  const coachId = get(userContext, "coach.id", undefined);

  const [isEmpty, setIsEmpty] = React.useState<boolean>(false);
  const [showCreditUsage, setShowCreditUsage] = React.useState(false);

  const addCampaignHandler = () => {
    history.push(URLS.coach.newVideoProject);
  };

  const customLinkSection = (): JSX.Element => {
    return (
      <SocialGrid.Column floated="right" className={"buttonRight"}>
        <Button
          data-elm-id={`campaignListAddCampaignBtn`}
          className={"primary buttonRight"}
          onClick={addCampaignHandler}
        >
          {formatMessage({ ...descriptors[CatalystType.addProjectBtn] })}
        </Button>
        <Button
          data-elm-id={`usageReport`}
          className={"secondary buttonRight"}
          onClick={() => {
            setShowCreditUsage(true);
          }}
        >
          Credit Usage Report
        </Button>
      </SocialGrid.Column>
    );
  };

  return (
    <WhiteContainer>
      <SideBar history={history} />

      <ContentGrid className={"listManagerContent"} columns={1}>
        <SocialGrid columns={1}>
          <TitleSection
            title={formatMessage({ ...descriptors[CatalystType.title] })}
            ready={true}
            showNextOption={false}
            titleSize={8}
            buttonsSize={8}
            showLinkOption={writePermission}
            customLinkSection={customLinkSection()}
          />
          <RowContainer>
            <SocialGrid.Column>
              {isEmpty ? (
                <EmptyView text={"No Video Projects"} />
              ) : (
                <VideoCatalystListContent coachId={coachId!} isEmptySection={setIsEmpty} />
              )}
            </SocialGrid.Column>
          </RowContainer>
        </SocialGrid>
      </ContentGrid>
      <VideoCreditUsageModal open={showCreditUsage} closeHandler={() => setShowCreditUsage(false)} coachId={coachId} />
    </WhiteContainer>
  );
};

export const VideoCatalyst = withRouter(VideoCatalystFC);
