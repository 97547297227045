import React, { FC, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Image } from "semantic-ui-react";
import { ContentPageMedia } from "../../../../../interfaces";
import "./styles.scss";

interface OwnProps {
  media: ContentPageMedia;
}

type Props = OwnProps;

const MediaPlayerFC: FC<Props> = ({ media }: Props) => {
  const [playWhenReady, setPlayWhenReady] = useState(false);
  const [mediaPlaying, setMediaPlaying] = useState(false);
  const [mediaReady, setMediaReady] = useState(false);

  useEffect(() => {
    if (playWhenReady && mediaReady) {
      setMediaPlaying(true);
    }
  }, [playWhenReady, mediaReady]);

  return (
    <div className={"pageMediaContainer"}>
      {media?.category === "VIDEO" && (
        <ReactPlayer
          url={media?.uri}
          width={"100%"}
          height={"auto"}
          controls
          playsinline
          playing={mediaPlaying}
          onClickPreview={() => {
            setPlayWhenReady(true);
          }}
          onReady={() => {
            setMediaReady(true);
          }}
          onPause={() => setMediaPlaying(false)}
        />
      )}
      {media?.category === "IMAGE" && <Image src={media?.uri} className={"mediaThumbnail"} />}
    </div>
  );
};

export const MediaPlayer = MediaPlayerFC;
