import { client as axios } from "./axiosClient";
import { catchServiceException, Constants } from "@socialcoach/services";
import * as DTO from "../interfaces/dtos";

const CONTENT_PAGE_SERVICE = `${Constants.API_URL}/content-page`;

export class ContentPageServices {
  @catchServiceException()
  static async getPublicPage(pageSlug: string): Promise<DTO.PublicContentPageInfo> {
    const { data } = await axios.get<DTO.PublicContentPageInfo>(`${CONTENT_PAGE_SERVICE}/public/${pageSlug}`);

    return data;
  }

  @catchServiceException()
  static async getPage(pageId: number): Promise<DTO.ContentPageInfo> {
    const { data } = await axios.get<DTO.ContentPageInfo>(`${CONTENT_PAGE_SERVICE}/${pageId}`, {
      withCredentials: true,
    });

    return data;
  }

  @catchServiceException()
  static async getPageBySlug(pageSlug: string): Promise<DTO.ContentPageInfo> {
    const { data } = await axios.get<DTO.ContentPageInfo>(`${CONTENT_PAGE_SERVICE}/bySlug/${pageSlug}`, {
      withCredentials: true,
    });

    return data;
  }

  @catchServiceException()
  static async save(pageRequest: DTO.ContentPageRequest): Promise<DTO.ContentPageInfo> {
    const { data } = await axios.post<DTO.ContentPageInfo>(`${CONTENT_PAGE_SERVICE}`, pageRequest, {
      withCredentials: true,
    });

    return data;
  }

  @catchServiceException()
  static async update(pageId: number, pageRequest: DTO.ContentPageRequest): Promise<DTO.ContentPageInfo> {
    const { data } = await axios.put<DTO.ContentPageInfo>(`${CONTENT_PAGE_SERVICE}/${pageId}`, pageRequest, {
      withCredentials: true,
    });

    return data;
  }

  @catchServiceException()
  static async archive(pageId: number): Promise<DTO.ContentPageInfo> {
    const { data } = await axios.delete<DTO.ContentPageInfo>(`${CONTENT_PAGE_SERVICE}/${pageId}`, {
      withCredentials: true,
    });

    return data;
  }

  @catchServiceException()
  static async registerView(pageSlug: string, viewRequest: DTO.ContentPageViewRequest): Promise<DTO.ContentPageInfo> {
    const { data } = await axios.post<DTO.ContentPageInfo>(
      `${CONTENT_PAGE_SERVICE}/public/${pageSlug}/view`,
      viewRequest
    );

    return data;
  }
}
