import * as React from "react";
import { VideoProjectCoachConfig, VideoProjectUsageReport } from "../../../../interfaces";
import {
  Button,
  Dropdown,
  DropdownItemProps,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import { VideoProjectServices } from "../../../../services";
import { useEffect, useMemo } from "react";
import "./styles.scss";
import { exportVCUsageToCsv, URLS } from "../../../../utils";

interface OwnProps {
  coachId?: string;
  open: boolean;
  closeHandler: () => void;
}

type Props = OwnProps;
const VideoCreditUsageModalFC: React.FC<Props> = ({ open, closeHandler, coachId }) => {
  const [coachConfig, setCoachConfig] = React.useState<VideoProjectCoachConfig>();
  const [startDate, setStartDate] = React.useState<string>();
  const [report, setReport] = React.useState<VideoProjectUsageReport>();

  const loadCoachConfig = async () => {
    if (coachId) {
      const config = await VideoProjectServices.getCoachConfig(coachId);
      setCoachConfig(config);
    }
  };

  const loadReport = async () => {
    setReport(undefined);
    if (startDate && coachId) {
      const start = new Date(startDate);
      const end = new Date(start.getFullYear(), start.getMonth() + 1, coachConfig?.newCycleDay);
      const reportData = await VideoProjectServices.getUsageReport(coachId, {
        startDate: start.toISOString(),
        endDate: end.toISOString(),
      });
      setReport(reportData);
    }
  };

  useEffect(() => {
    if (coachId) loadCoachConfig();
  }, [coachId]);

  const exportUsageReport = () => {
    const start = new Date(startDate!);
    const end = new Date(start.getFullYear(), start.getMonth() + 1, coachConfig?.newCycleDay);
    exportVCUsageToCsv(`${start.toLocaleDateString()}-${end.toLocaleDateString()}`, report?.playerUsage!);
  };

  const billingCycleOptions: DropdownItemProps[] = useMemo(() => {
    if (coachConfig?.newCycleDay) {
      const today = new Date();
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth();

      const options = [];

      let cycleStartDate = new Date(currentYear, currentMonth, coachConfig?.newCycleDay);
      if (cycleStartDate > today) {
        cycleStartDate.setMonth(cycleStartDate.getMonth() - 1);
      }

      for (let i = 0; i < 13; i++) {
        const nextMonth = new Date(
          cycleStartDate.getFullYear(),
          cycleStartDate.getMonth() + 1,
          coachConfig?.newCycleDay
        );
        const cycleEndDate = new Date(nextMonth.setDate(nextMonth.getDate() - 1));

        options.push({
          text: `${cycleStartDate.toLocaleDateString()} - ${cycleEndDate.toLocaleDateString()}`,
          value: cycleStartDate.toLocaleDateString(),
        });
        cycleStartDate.setMonth(cycleStartDate.getMonth() - 1);
      }

      if (!startDate) setStartDate(options[0].value);

      return options;
    } else {
      return [];
    }
  }, [coachConfig?.newCycleDay, startDate, setStartDate]);

  useEffect(() => {
    loadReport();
  }, [startDate, coachId]);

  return (
    <Modal open={open} size={"large"} onClose={closeHandler} closeIcon={true}>
      <Modal.Header>Video Credit Usage</Modal.Header>
      <Modal.Content>
        <Dropdown
          placeholder="Select Billing Cycle"
          fluid
          selection
          options={billingCycleOptions}
          value={startDate}
          onChange={(_, data) => setStartDate(data.value?.toString())}
        />
        <div className={`vcCreditUsageTable`}>
          <Table celled>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Player</TableHeaderCell>
                <TableHeaderCell>VC Enabled</TableHeaderCell>
                <TableHeaderCell>Current Credit Limit</TableHeaderCell>
                <TableHeaderCell>Credits Used</TableHeaderCell>
              </TableRow>
            </TableHeader>

            <TableBody>
              {report?.playerUsage?.map(playerUsage => {
                return (
                  <TableRow key={`player-usage-${playerUsage.playerId}`}>
                    <TableCell>
                      <a href={URLS.coach.editPlayer.replace(":playerId", `${playerUsage.playerId}`)} target="_blank">
                        {playerUsage.playerName}
                      </a>
                      <div>{playerUsage.playerEmail}</div>
                    </TableCell>
                    <TableCell>{playerUsage.currentlyEnabled ? "Yes" : "No"}</TableCell>
                    <TableCell>{playerUsage.currentCreditLimit || "Unlimited"}</TableCell>
                    <TableCell>{playerUsage.creditsUsed}</TableCell>
                  </TableRow>
                );
              })}

              {report?.playerUsage && (
                <TableRow active>
                  <TableCell>Total</TableCell>
                  <TableCell>
                    {report.playerUsage.filter(p => p.currentlyEnabled).length}/{report.playerUsage.length}
                  </TableCell>
                  <TableCell>--</TableCell>
                  <TableCell>{report.playerUsage.reduce((acc, p) => acc + p.creditsUsed, 0)}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          data-elm-id={`basicModalYesBtn`}
          onClick={() => {
            exportUsageReport();
          }}
        >
          Export
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const VideoCreditUsageModal = VideoCreditUsageModalFC;
