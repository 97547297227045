import * as React from "react";
import { Formik } from "formik";

import { validationSchema } from "./validations";
import { ContentPageMedia, ContentPageRequest } from "../../../../../interfaces";
import { Dropdown, DropdownItemProps, Form, Input, Loader, Message } from "semantic-ui-react";
import { MediaUploader } from "../MediaUploader";
import { useDebounce } from "../../../../../utils";
import { useEffect, useState } from "react";
import { SubscriptionServices } from "../../../../../services";

export interface OwnProps {
  pageRequest?: ContentPageRequest;
  isNew: boolean;
  pageErrors?: string[];

  disabled?: boolean;
  onUpdate: (values: any) => void;
  onUpdateFile: (src: File | undefined) => void;
}

type Props = OwnProps;

const ContentPageFormFC: React.FC<Props> = ({ isNew, pageRequest, onUpdate, onUpdateFile }) => {
  const [playerOptions, setPlayerOptions] = useState<DropdownItemProps[]>([]);
  const [playerSearchTerm, setPlayerSearchTerm] = useState<string>("");

  const loadPlayers = async () => {
    const playersResponse = await SubscriptionServices.searchAllSubscriptions(playerSearchTerm, 0, 20);
    const options = playersResponse.content.map(player => {
      return {
        value: player.playerId,
        text: `${player.firstName} ${player.lastName}`.trim(),
        description: player.email,
      } as DropdownItemProps;
    });
    setPlayerOptions(options);
  };

  const playerSearch = useDebounce(() => {
    if (playerSearchTerm.length > 0) {
      loadPlayers();
    }
  }, 300);

  useEffect(() => {
    playerSearch();
  }, [playerSearchTerm]);

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={true}
      validationSchema={validationSchema}
      initialValues={{
        playerId: pageRequest?.playerId,
        coachId: pageRequest?.coachId,
        title: pageRequest?.title,
        caption: pageRequest?.caption,
        media: pageRequest?.media || [],
        hasMedia: pageRequest?.media?.length,
      }}
      onSubmit={async () => {
        // Action removed because the client wants
        // to use TitleSection action to save the prompt
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue }) => {
        const errorMessages = Object.values(errors) as string[];
        onUpdate(values);
        return (
          <Form size="large" error={errorMessages.length > 0} className={"contentPageForm"}>
            {isSubmitting && <Loader active size="large" />}
            {errorMessages.length > 0 && <Message error list={errorMessages} />}

            <Form.Field>
              <label>Title</label>

              <Input
                id={"title"}
                placeholder="Page Title"
                value={values.title}
                onChange={e => {
                  setFieldValue("title", e.currentTarget.value);
                }}
              />
            </Form.Field>

            {isNew && (
              <Form.Field>
                <label>Player (Optional)</label>

                <Dropdown
                  id={"playerId"}
                  fluid
                  selection
                  placeholder={"Search for a player..."}
                  value={values.playerId}
                  onSearchChange={(_, data) => setPlayerSearchTerm(data.searchQuery)}
                  options={playerOptions}
                  search={options => options}
                  onChange={(e, data) => {
                    setFieldValue("playerId", data.value);
                  }}
                />
              </Form.Field>
            )}

            <MediaUploader
              onUpdateMedia={(newMedia: ContentPageMedia | undefined, newFile: File | undefined) => {
                setFieldValue("hasMedia", !!newMedia);
                setFieldValue("media", newMedia ? [newMedia] : []);
                onUpdateFile(newFile);
              }}
              media={values.media[0]}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export const ContentPageForm = ContentPageFormFC;
