import * as React from "react";

import { PublicContentPageInfo } from "./interfaces";
import { PublicContentPage } from "./containers";

interface Props {
  pageInfo: PublicContentPageInfo;
}

const ContentPageApp: React.FC<Props> = ({ pageInfo }) => {
  return <PublicContentPage pageInfo={pageInfo} />;
};

export default ContentPageApp;
