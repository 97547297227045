import { PlayerAccount } from "./Player";

export interface BasePlayerStatisticsResponse {
  statistics: BasePlayerStatistics;
}

export interface BasePlayerStatistics {
  VIEWS?: Statistic;
  CLICKS?: Statistic;
  earned?: Statistic;
  GOALS?: GoalStatistic;
  FOLLOWERS?: Statistic;
  LIKES?: Statistic;
  SHARES?: Statistic;
  COMMENTS?: Statistic;
}

export interface Statistic {
  percent: number;
  value: number;
}

export interface GoalStatistic {
  currentGoals: number;
  expectedGoals: number;
}

export interface RemittanceReport {
  lifetimeRevenue?: number;
  monthlyRecurrentRevenue?: number;
  nextRemittance?: number;
  previousRemittance?: number;
  monthToDay?: number;
  startDateNextMonth?: Date;
  percentageOfRemittanceChange?: number;
  percentageOfMonthToDayChange?: number;
}

export enum StatisticInterval {
  monthly = "monthly",
  weekly = "weekly",
  daily = "daily",
}

export enum PlayerStatisticType {
  TRIAL_PLAYERS = "TRIAL_PLAYERS",
  ACTIVE_PLAYERS = "ACTIVE_PLAYERS",
  INACTIVE_PLAYERS = "INACTIVE_PLAYERS",
  CANCELED_PLAYERS = "CANCELED_PLAYERS",
  LIFETIME_PLAYERS = "LIFETIME_PLAYERS",
}
export interface PlayerReportStats {
  from?: Date;
  to?: Date;
  intervalType: StatisticInterval;
  statType?: PlayerStatisticType;
  statValue?: number;
}

export interface PlayerCoachReportSummary {
  absoluteChange?: number;
  percentageChange?: number;
  lastUpdateDatetime: Date;
  statisticType?: PlayerStatisticType;
}

export interface PromptEngagementCoachReport {
  lifetimeSent?: number;
  lifetimeCompleted?: number;
  engagementLifetime?: number;
  monthSent?: number;
  monthCompleted?: number;
  engagementMonth?: number;
  published?: number;
  remaining?: number;
  publishSent?: number;
  randomScheduleDays?: number;
  lastScheduledDeliveryDate?: string;
  lastSuccessfulDeliveryDate?: string;
}

export interface PlayerReport {
  coachId: string;
  stats: PlayerReportStats[];
  summaries: PlayerCoachReportSummary[];
}

export interface StatisticResponse {
  remittanceReport: RemittanceReport;
  playersReport: PlayerReport;
  promptEngagementReport: PromptEngagementCoachReport;
}

export interface CoachStatistic {
  coachId: string;
  remittanceReport: RemittanceReport;
  playersReport: PlayerReport;
  promptEngagementReport: PromptEngagementCoachReport;
}

export interface CampaignSubscribedStatistic {
  playerAccount: PlayerAccount;
  countPromptReceived: number;
  countPromptPosted: number;
  countPromptRemaining: number;
}
