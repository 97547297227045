import * as React from "react";
import { Grid, Form, Radio, Loader, Modal, Button, Dimmer, Header, Input } from "semantic-ui-react";
import { Account, BaseCoach, AdminWebSetting, WebSettings, VideoProjectCoachConfig } from "../../../interfaces";
import { SettingsServices, VideoProjectServices } from "../../../services";

import { modalReducer } from "./modalReducer";
import { appSettings } from "./formFields";
import "./styles.scss";
import { useEffect, useMemo } from "react";

interface OwnProps {
  opened: boolean;
  coach: BaseCoach;
  handleUpdateSuccess: () => void;
}

const CoachPermissionsFC: React.FC<OwnProps> = ({ coach, opened, handleUpdateSuccess }) => {
  const [state, dispatch] = React.useReducer(modalReducer, { open: opened });

  const [loading, setLoading] = React.useState<boolean>(false);
  const [configLoading, setConfigLoading] = React.useState<boolean>(false);
  const [permissions, setPermissions] = React.useState<{ [key: string]: string }>({});
  const [coachWebSettings, setCoachWebSettings] = React.useState<AdminWebSetting | undefined>(undefined);
  const [defaultPlayerCredits, setDefaultPlayerCredits] = React.useState<number>();
  const [billingCycleResetDay, setBillingCycleResetDay] = React.useState<number>();
  const [vcConfig, setVCConfig] = React.useState<VideoProjectCoachConfig>();

  const hasVC = useMemo(() => {
    return "ACTIVE" == coachWebSettings?.settings?.find(s => s.webSettingId === WebSettings.SC_PLUS_TIER)?.status;
  }, [coachWebSettings?.settings]);

  React.useEffect(() => {
    const getSettings = async () => {
      if (coach.id) {
        setLoading(true);
        const settings = await SettingsServices.getAdminSettingsByCoachId(coach.id);
        setCoachWebSettings(settings);
        setLoading(false);
      }
    };

    const getVCConfig = async () => {
      if (coach.id) {
        setConfigLoading(true);
        const config = await VideoProjectServices.getCoachConfig(coach.id);
        setVCConfig(config);
        setConfigLoading(false);
      }
    };

    getSettings();
    getVCConfig();
  }, [coach.id]);

  useEffect(() => {
    setDefaultPlayerCredits(vcConfig?.defaultPlayerVideoCredits);
    setBillingCycleResetDay(vcConfig?.newCycleDay);
  }, [vcConfig]);

  const hasConfigChanges = useMemo(() => {
    return vcConfig?.defaultPlayerVideoCredits != defaultPlayerCredits || vcConfig?.newCycleDay != billingCycleResetDay;
  }, [vcConfig?.defaultPlayerVideoCredits, defaultPlayerCredits, vcConfig?.newCycleDay, billingCycleResetDay]);

  React.useEffect(() => {
    if (coachWebSettings) {
      setPermissions({
        externalPost:
          coachWebSettings.settings.find(s => s.webSettingId === WebSettings.EXTERNAL_POST)?.status || "INACTIVE",
        failedPost:
          coachWebSettings.settings.find(s => s.webSettingId === WebSettings.FAILED_POST_NOTIFICATION)?.status ||
          "INACTIVE",
        tiktok: coachWebSettings.settings.find(s => s.webSettingId === WebSettings.TIKTOK)?.status || "INACTIVE",
        coachSummaryReport:
          coachWebSettings.settings.find(s => s.webSettingId === WebSettings.SUMMARY_REPORT_COACH)?.status ||
          "INACTIVE",
        costCenter:
          coachWebSettings.settings.find(s => s.webSettingId === WebSettings.COST_CENTER)?.status || "INACTIVE",
        scPlusTier:
          coachWebSettings.settings.find(s => s.webSettingId === WebSettings.SC_PLUS_TIER)?.status || "INACTIVE",
        accessScriptLibrary:
          coachWebSettings.settings.find(s => s.webSettingId === WebSettings.ACCESS_SCRIPT_LIBRARY)?.status ||
          "INACTIVE",
        manageScriptLibrary:
          coachWebSettings.settings.find(s => s.webSettingId === WebSettings.MANAGE_SCRIPT_LIBRARY)?.status ||
          "INACTIVE",
      });
    }
  }, [coachWebSettings]);

  const handleSettingsChange = (e: any, data: any) => {
    setPermissions(p => {
      return {
        ...p,
        [data.name]: data.checked ? "ACTIVE" : "INACTIVE",
      };
    });
  };

  const updateAdminSettings = async (): void => {
    setLoading(true);
    const settingsRequest: AdminWebSetting = {
      coachId: coach.id,
      settings: [
        {
          webSettingId: WebSettings.EXTERNAL_POST,
          status: permissions["externalPost"],
        },
        {
          webSettingId: WebSettings.FAILED_POST_NOTIFICATION,
          status: permissions["failedPost"],
        },
        {
          webSettingId: WebSettings.TIKTOK,
          status: permissions["tiktok"],
        },
        {
          webSettingId: WebSettings.SUMMARY_REPORT_COACH,
          status: permissions["coachSummaryReport"],
        },
        {
          webSettingId: WebSettings.COST_CENTER,
          status: permissions["costCenter"],
        },
        {
          webSettingId: WebSettings.SC_PLUS_TIER,
          status: permissions["scPlusTier"],
        },
        {
          webSettingId: WebSettings.ACCESS_SCRIPT_LIBRARY,
          status: permissions["accessScriptLibrary"],
        },
        {
          webSettingId: WebSettings.MANAGE_SCRIPT_LIBRARY,
          status: permissions["manageScriptLibrary"],
        },
      ],
    };

    await SettingsServices.updateAdminSettings(settingsRequest);
    if (hasVC && hasConfigChanges) {
      await VideoProjectServices.updateCoachConfig(coach.id, {
        defaultPlayerVideoCredits: defaultPlayerCredits,
        newCycleDay: billingCycleResetDay || 1,
      });
    }
    dispatch({ type: "close" });
    handleUpdateSuccess();
    setLoading(false);
  };
  const permissionToggles = (
    <React.Fragment>
      <Grid columns={1} padded>
        <Grid.Column>
          <Form.Group grouped key={"appSettingsGroupPermissions"}>
            {appSettings.map(appSetting => {
              return (
                <>
                  <Form.Group inline key={appSetting.id}>
                    <Radio
                      toggle
                      label={appSetting.label}
                      className={"settingsLevelLabel"}
                      onChange={handleSettingsChange}
                      name={appSetting.id}
                      checked={permissions && permissions[appSetting.id] === "ACTIVE"}
                    />
                  </Form.Group>
                </>
              );
            })}
          </Form.Group>
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );

  const vcSettings = (
    <>
      <label>Default Player Monthly Credits</label>
      <Input
        placeholder="Default Player Monthly Credits"
        type={"number"}
        value={defaultPlayerCredits}
        onChange={data => {
          setDefaultPlayerCredits(data.target.valueAsNumber);
        }}
      />

      <label>Billing Cycle Reset Day</label>
      <Input
        placeholder="Billing Cycle Reset Day"
        type={"number"}
        value={billingCycleResetDay}
        onChange={data => {
          setBillingCycleResetDay(data.target.valueAsNumber);
        }}
      />
    </>
  );

  return (
    <Modal
      data-elm-id={`admin-dashboard-modal-coach-permission-${coach.id}`}
      open={state.open}
      onClose={() => {
        dispatch({ type: "close" });
        handleUpdateSuccess();
      }}
      closeIcon={true}
    >
      <Modal.Header>Coach Settings</Modal.Header>
      <Modal.Content>
        <Grid
          columns={16}
          className="adminPermissionContainer"
          verticalAlign="middle"
          stretched
          container
          textAlign={"left"}
        >
          <Grid.Row>
            <Grid.Column width={16}>
              <Header>Permissions</Header>
              {loading ? (
                <Dimmer active inverted>
                  <Loader size="medium">Loading</Loader>
                </Dimmer>
              ) : (
                permissionToggles
              )}
            </Grid.Column>
          </Grid.Row>
          {hasVC && (
            <Grid.Row>
              <Grid.Column width={8}>
                <Header>Video Catalyst Settings</Header>
                {configLoading ? (
                  <Dimmer active inverted>
                    <Loader size="medium">Loading</Loader>
                  </Dimmer>
                ) : (
                  vcSettings
                )}
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Modal.Content>

      <Modal.Actions>
        <Button data-elm-id={`admin-dashboard-modal-coach-permission-save-btn`} primary onClick={updateAdminSettings}>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const CoachPermissions = CoachPermissionsFC;
