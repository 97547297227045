import { ExportToCsv } from "export-to-csv";
import {
  ExportPlayerObject,
  ExportPostFeedObject,
  PlayerWithStats,
  Post,
  CompleteAccount,
  ExportCoachObject,
  PlayerSearchResponse,
  ExportBasicPlayerObject,
  SocialNetworksId,
  socialNetworksWithName,
  ExportPlayerObjectDashboard,
  SocialNetwork,
  SubscribedPlayerSearchResponse,
  ExportAdminPlayerObject,
  PlayerAccount,
  VideoProjectPlayerUsage,
} from "../interfaces";
import {
  userFullName,
  getStatusText,
  getDefaultTotal,
  getSocialNetworkTotal,
  getSubscriptionType,
  getFullName,
} from "./helpers";

const BLANK_LINES = 3;

const DEFAULT_CSV_OPTIONS = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  showTitle: true,
  useTextFile: false,
  useBom: true,
};

export const DEFAULT_PLAYER_FIELDS = [
  "Player Name",
  "Player Email",
  "Created Date",
  "Subscription Status",
  "Subscription Type",
  "Total Social Networks",
  "Phone",
  "Sign Up Date",
  "Branch",
  "Region",
  "Cost Centers",
  "Facebook Connected?",
  "Instagram Connected?",
  "LinkedIn Connected?",
  "X Connected?",
  "TikTok Connected?",
];

export const DEFAULT_CANCELED_PLAYER_FIELDS = [
  "Player Name",
  "Player Email",
  "Created Date",
  "Subscription Status",
  "Phone",
  "Sign Up Date",
  "Canceled Date",
  "Branch",
  "Region",
  "Cost Center",
];

export const LEADERBOARD_FIELDS_BASE = [
  "Player Name",
  "Player Email",
  "Social Network",
  "Branches",
  "Regions",
  "Followers",
  "Post",
  "Likes",
  "Comments",
  "Shares",
  "Clicks",
];

export const LEADERBOARD_FIELDS = [
  "Player Name",
  "Player Email",
  "Social Network",
  "Branches",
  "Regions",
  "Cost Centers",
  "Followers",
  "Post",
  "Likes",
  "Comments",
  "Shares",
  "Clicks",
];

/**
 * Generate a list of ExportPlayerObject to be exported in a csv file
 * @param players List of SubscribedPlayerWithStatsSearchResponse with the data
 * @returns list of ExportPlayerObject to be exported in a csv file
 */
export function exportPlayerList(
  players: SubscribedPlayerSearchResponse[],
  removeHeaders?: string[]
): ExportPlayerObject[] {
  const exportList: ExportPlayerObject[] = [];

  players.map(({ pricePlanSubscription, playerAccount, branchNames, regionNames, costCenterNames }) => {
    const { phone, email, socialNetworks, createdDate, verificationDate } = playerAccount;

    const playerInfo: ExportPlayerObject = {
      name: userFullName(playerAccount),
      email: email || "-",
      createdDate,
      subscription_status: getStatusText(pricePlanSubscription!!.status!!),
      subscription_tier: getSubscriptionType(pricePlanSubscription?.pricePlan.tier),
      total_social_networks: socialNetworks?.length || "-",
      phone: phone || "-",
      signUpDate: verificationDate,
      branches: branchNames ? branchNames.join(", ") : "-",
      regions: regionNames ? regionNames.join(", ") : "-",
      costCenters: costCenterNames ? costCenterNames.join(", ") : "-",
      facebookConnected: getSocialNetworkStatus(socialNetworks, "FB"),
      instagramConnected: getSocialNetworkStatus(socialNetworks, "IG"),
      linkedinConnected: getSocialNetworkStatus(socialNetworks, "LI"),
      xConnected: getSocialNetworkStatus(socialNetworks, "TW"),
      tiktokConnected: getSocialNetworkStatus(socialNetworks, "TT"),
    };

    if (removeHeaders) {
      removeHeaders.forEach(header => {
        delete playerInfo[header];
      });
    }

    exportList.push(playerInfo);
  });

  return exportList;
}

/**
 * Generate a list of ExportPlayerObject to be exported in a csv file
 * @param players List of SubscribedPlayerWithStatsSearchResponse with the data
 * @returns list of ExportPlayerObject to be exported in a csv file
 */
export function exportCanceledPlayerList(
  players: SubscribedPlayerSearchResponse[],
  removeHeaders?: string[]
): ExportPlayerObject[] {
  const exportList: ExportPlayerObject[] = [];

  players.map(({ pricePlanSubscription, playerAccount, branchNames, regionNames, canceledDate, costCenterNames }) => {
    const { phone, email, createdDate, verificationDate } = playerAccount;

    const playerInfo: ExportPlayerObject = {
      name: userFullName(playerAccount),
      email: email || "-",
      createdDate,
      subscription_status: getStatusText(pricePlanSubscription!!.status!!),
      phone: phone || "-",
      signUpDate: verificationDate || "-",
      canceledDate,
      branches: branchNames ? branchNames.join(", ") : "-",
      regions: regionNames ? regionNames.join(", ") : "-",
      costCenters: costCenterNames ? costCenterNames.join(", ") : "-",
    };

    if (removeHeaders) {
      removeHeaders.forEach(header => {
        delete playerInfo[header];
      });
    }

    exportList.push(playerInfo);
  });

  return exportList;
}

/**
 * Internal function to detect if the user has an specific social network connected
 * @param socialNetworks List of user's social networks
 * @param socialNetworkId Social network to verify
 * @returns "Y" if the social network is connected or "N" otherwise
 */
function getSocialNetworkStatus(
  socialNetworks: SocialNetwork[] | undefined,
  socialNetworkId: SocialNetworksId
): string {
  const socialNetwork = socialNetworks?.filter(sn => sn.networkId === socialNetworkId);
  if (socialNetwork !== undefined && socialNetwork.length > 0) {
    return "Y";
  } else {
    return "N";
  }
}

function addExportPlayerObjectDashboardBlankLine(): ExportPlayerObjectDashboard {
  return {
    name: "",
    email: "",
    social_network: "",
    branch_names: "",
    region_names: "",
    cost_center_names: "",
    followers: "",
    posts: "",
    likes: "",
    comments: "",
    shares: "",
    clicks: "",
  };
}

export function exportPlayerStatsList(
  players: PlayerWithStats[],
  availableSocialNetwork: SocialNetworksId[],
  removeHeaders?: string[]
): ExportPlayerObjectDashboard[] {
  const exportList: ExportPlayerObjectDashboard[] = [];

  players.map(player => {
    const {
      info,
      email,
      countPromptPosted,
      followers,
      likes,
      comments,
      shares,
      click,
      statsBySocialNetwork,
      branchNames,
      regionNames,
      costCenterNames,
    } = player;

    const playerInfo: ExportPlayerObjectDashboard = {
      name: userFullName(info) || "-",
      email: email || "-",
      social_network: "Total",
      branch_names: branchNames?.join() || "",
      region_names: regionNames?.join() || "",
      cost_center_names: costCenterNames?.join() || "",
      followers,
      posts: countPromptPosted || "-",
      likes,
      comments,
      shares,
      clicks: click,
    };

    if (removeHeaders) {
      removeHeaders.forEach(header => {
        delete playerInfo[header];
      });
    }
    exportList.push(playerInfo);

    Object.keys(statsBySocialNetwork).map(sn => {
      const playerSNInfo = {
        name: userFullName(info),
        email: email || "-",
        social_network: socialNetworksWithName[sn],
        branch_names: branchNames?.join() || "",
        region_names: regionNames?.join() || "",
        cost_center_names: costCenterNames?.join() || "",
        followers: player.statsBySocialNetwork[sn].followers,
        posts: player.statsBySocialNetwork[sn].countPromptPosted || "0",
        likes: player.statsBySocialNetwork[sn].likes,
        comments: player.statsBySocialNetwork[sn].comments,
        // views: player.statsBySocialNetwork[sn].views,
        shares: player.statsBySocialNetwork[sn].shares,
        clicks: player.statsBySocialNetwork[sn].click,
      };
      if (removeHeaders) {
        removeHeaders.forEach(header => {
          delete playerSNInfo[header];
        });
      }
      exportList.push(playerSNInfo);
    });
  });

  const blankLine = addExportPlayerObjectDashboardBlankLine();
  if (removeHeaders) {
    removeHeaders.forEach(header => {
      delete blankLine[header];
    });
  }

  [...Array(BLANK_LINES)].map(() => exportList.push(blankLine));

  const total = getDefaultTotal(players);

  const totalRow = {
    name: "",
    email: "",
    social_network: "Total",
    branch_names: "",
    region_names: "",
    cost_center_names: "",
    followers: total.followers,
    posts: total.countPromptPosted,
    likes: total.likes,
    comments: total.comments,
    // views: total.views,
    shares: total.shares,
    clicks: total.click,
  };
  if (removeHeaders) {
    removeHeaders.forEach(header => {
      delete totalRow[header];
    });
  }

  exportList.push(totalRow);

  availableSocialNetwork.forEach(sn => {
    const snResult = getSocialNetworkTotal(players, sn);

    const snRow = {
      name: "",
      email: "",
      social_network: socialNetworksWithName[sn] + " Total",
      branch_names: "",
      region_names: "",
      cost_center_names: "",
      followers: snResult.followers,
      posts: snResult.countPromptPosted,
      likes: snResult.likes,
      comments: snResult.comments,
      //  views: snResult.views,
      shares: snResult.shares,
      clicks: snResult.click,
    };
    if (removeHeaders) {
      removeHeaders.forEach(header => {
        delete snRow[header];
      });
    }
    exportList.push(snRow);
  });

  return exportList;
}

export function convertToAdminPlayerExport(players: PlayerSearchResponse[]): ExportAdminPlayerObject[] {
  return players.map(player => {
    return {
      email: player.email,
      phone: player.phone,
      firstName: player.firstName,
      lastName: player.lastName,
      verificationDate: player.verificationDate,
      coachEmail: player.coachProfile?.email,
      subscriptionStatus: player.subscriptionSummary?.status,
      tier: player.subscriptionSummary?.tier,
      type: player.subscriptionSummary?.type,
    };
  });
}

export function exportCoachesList(coaches: CompleteAccount[]): ExportCoachObject[] {
  return coaches.map(coach => {
    const { profile, user } = coach;

    return {
      name: profile ? userFullName(profile!!) : "",
      email: user.email || "-",
      addedDate: user.createdDate,
      status: user.status,
    };
  });
}

export function exportPostFeedList(
  posts: Post[],
  playerList: { [key: string]: PlayerAccount }
): ExportPostFeedObject[] {
  const exportList: ExportPostFeedObject[] = [];

  posts.map(post => {
    const {
      socialNetworks,
      message,
      keyphrases,
      coachPostReviewStatus,
      coachPostReviewStatusUpdatedDate,
      postReviewRequestedByFullName,
      playerBranch,
      playerRegion,
    } = post;
    const firstPostedDate = socialNetworks && socialNetworks.length > 0 ? socialNetworks[0].postedTimestamp : "";
    const player = playerList[post.playerId];
    const playerName = getFullName(player?.firstName, player?.lastName);
    exportList.push({
      postDate: firstPostedDate,
      name: playerName,
      textOfPost: message,
      linkToPost: socialNetworks.map(sn => sn.externalPostUrl).join(", "),
      keywords: keyphrases.map(key => key.content).join(", "),
      branch: playerBranch || "-",
      region: playerRegion || "-",
      reviewStatus: coachPostReviewStatus || "-",
      reviewedBy: coachPostReviewStatus ? postReviewRequestedByFullName : "-",
      reviewDate: coachPostReviewStatusUpdatedDate || "-",
    });
  });

  return exportList;
}

/**
 * Generates a csv file with Players info using the data sent by parameter
 * @param filename Name of the file that going to be generated
 * @param exportData List of elements in the correct format
 * (ExportPlayerObject, ExportBasicPlayerObject or ExportPlayerObjectDashboard) to exported
 * @param listOfHeaders (Optional) If this field is included the csv going to use these headers
 * instead of the name of attributes
 */
export function exportPlayerToCsv(
  filename: string = "",
  exportData: ExportPlayerObject[] | ExportBasicPlayerObject[] | ExportPlayerObjectDashboard[],
  listOfHeaders?: string[]
): void {
  if (exportData.length === 0) {
    return;
  }
  const options = {
    ...DEFAULT_CSV_OPTIONS,
    filename: `${filename}_players`,
    title: `${filename} Players`,
    useKeysAsHeaders: listOfHeaders === undefined,
    headers: listOfHeaders, // <-- Won't work with useKeysAsHeaders present!
  };

  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(exportData);
}

export function exportBasicPlayerToCsv(filename: string = "", exportData: ExportBasicPlayerObject[]): void {
  if (exportData.length === 0) {
    return;
  }
  const options = {
    ...DEFAULT_CSV_OPTIONS,
    filename: `${filename}_players`,
    title: `${filename} Players`,
    useKeysAsHeaders: true,
  };

  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(exportData);
}

/**
 * Generates a csv file with Coaches info using the data sent by parameter
 * @param filename Name of the file that going to be generated
 * @param exportData List of elements in the correct format (ExportCoachObject) to exported
 */
export function exportCoachesToCsv(filename: string = "", exportData: ExportCoachObject[]): void {
  if (exportData.length === 0) {
    return;
  }
  const options = {
    ...DEFAULT_CSV_OPTIONS,
    filename: `${filename}_coaches`,
    title: `${filename} coaches`,
    useKeysAsHeaders: true,
  };

  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(exportData);
}

/**
 * Generates a csv file with Player info using the data sent by parameter
 * @param filename Name of the file that going to be generated
 * @param exportData List of elements in the correct format (ExportAdminPlayerObject) to exported
 */
export function exportAdminPlayersToCsv(filename: string = "", exportData: ExportAdminPlayerObject[]): void {
  if (exportData.length === 0) {
    return;
  }
  const options = {
    ...DEFAULT_CSV_OPTIONS,
    filename: `${filename}`,
    title: `${filename}`,
    useKeysAsHeaders: true,
  };

  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(exportData);
}

/**
 * Generates a csv file including the data sent by parameter with Post Feed info
 * @param filename Name of the file that going to be generated
 * @param exportData List of elements in the correct format (ExportPostFeedObject) to exported
 */
export function exportPostFeedToCsv(filename: string = "", exportData: ExportPostFeedObject[]): void {
  if (exportData.length === 0) {
    return;
  }
  const listOfHeaders = [
    "Post Date",
    "Name",
    "Text of Post",
    "Link to Post",
    "Keywords/phrases included",
    "Branch",
    "Region",
    "Review Status",
    "Status Changed by",
    "Date/Time Status Changed",
  ];
  const options = {
    ...DEFAULT_CSV_OPTIONS,
    filename: `${filename}_feeds`,
    title: `${filename} Post Feed`,
    useKeysAsHeaders: false,
    headers: listOfHeaders, // <-- Won't work with useKeysAsHeaders present!
  };

  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(exportData);
}

export function exportVCUsageToCsv(filename: string = "", exportData: VideoProjectPlayerUsage[]): void {
  if (exportData.length === 0) {
    return;
  }
  const options = {
    ...DEFAULT_CSV_OPTIONS,
    filename: `vc_credit_usage-${filename}`,
    title: `${filename} VideoCatalyst Credit Usage`,
    useKeysAsHeaders: false,
    headers: [
      "Player Email",
      "Player Name",
      "VideoCatalyst Enabled",
      "Current Video Credit Limit",
      "Video Credits Used",
    ],
  };

  const csvExporter = new ExportToCsv(options);
  csvExporter.generateCsv(
    exportData.map(row => {
      return {
        "Player Email": row.playerEmail,
        "Player Name": row.playerName,
        "VideoCatalyst Enabled": row.currentlyEnabled ? "Yes" : "No",
        "Current Video Credit Limit": row.currentCreditLimit || "Unlimited",
        "Video Credits Used": row.creditsUsed,
      };
    })
  );
}
