import * as React from "react";
import { ContentPageInfo } from "../../../../../interfaces";
import { Image } from "semantic-ui-react";
import "./styles.scss";

export interface OwnProps {
  pageInfo?: ContentPageInfo;
}

type Props = OwnProps;

const ContentPageDisplayFC: React.FC<Props> = ({ pageInfo }) => {
  return (
    pageInfo && (
      <div className={"content-page-display"}>
        <div>
          <label>Title: {pageInfo.title}</label>
        </div>
        <div>
          <label>
            Link:{" "}
            <a href={pageInfo.url} target="_blank">
              {pageInfo.url}
            </a>
          </label>
        </div>
        <div>
          <label>Previews</label>
          {pageInfo.previewMedia.map(preview => {
            return <Image key={`preview-${preview.previewType}`} src={preview.uri} className={"preview-image"} />;
          })}
        </div>

        <div>
          <label>Views:</label>
          {pageInfo.contentPageViewSummary.viewCount}
        </div>
        <div>
          <label>Devices:</label>
          <ul>
            {pageInfo.contentPageViewSummary.deviceClasses.map((s, index) => {
              return (
                <li key={`device-stat-${index}`}>
                  {s.label} - {s.percentage}%
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <label>Cities:</label>
          <ul>
            {pageInfo.contentPageViewSummary.cities.map((s, index) => {
              return (
                <li key={`cities-stat-${index}`}>
                  {s.label} - {s.percentage}%
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    )
  );
};

export const ContentPageDisplay = ContentPageDisplayFC;
